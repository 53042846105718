import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";

const ContentBlock = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 40px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const About = () => {
  return (
    <>
      <ContentBlock>
        <h1>Acerca de Infinity Tech España</h1>
        <p>
          Infinity Tech España es una empresa innovadora dedicada a la
          transformación digital y al desarrollo de soluciones en inteligencia
          artificial. Fundada en Madrid, nos especializamos en automatizar procesos
          empresariales y ofrecer herramientas tecnológicas que impulsan el crecimiento
          y la eficiencia. Nuestro equipo de expertos trabaja incansablemente para
          diseñar e implementar proyectos que no solo resuelven problemas actuales,
          sino que también anticipan las necesidades del futuro. ¡Bienvenido a un
          mundo de posibilidades infinitas!
        </p>
      </ContentBlock>
    </>
  );
};

export default About;
