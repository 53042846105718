import React from "react";
import styled from "styled-components";

// Контейнер для двох блоків
const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    justify-content: center;
    background-color: #f8f8f8;
    padding-top: 80px;
`;

// Блок з інформацією (картинка + текст)
const InfoBlock = styled.div`
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

// Картинка з поясненням
const Image = styled.img`
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
`;

// Описовий текст
const Text = styled.p`
    font-size: 16px;
    line-height: 1.5;
    color: #333;
`;

// Блок з формою заявки
const FormBlock = styled.div`
    flex: 1;
    min-width: 300px;
    max-width: 600px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
`;

// Стилізація форми
const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

// Стилізація інпутів
const Input = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
`;

// Стилізована кнопка
const Button = styled.button`
    padding: 12px;
    background-color: #3b82f6;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #2563eb;
    }
`;

// Контейнер для угоди з умовами
const AgreementContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  margin-top: 10px;

  input[type="checkbox"] {
    margin-right: 8px;
  }

  a {
    color: #3b82f6;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const InfinityComponent = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    // Використовуємо fetch для відправки даних на thanks/index.php
    fetch("thanks/index.php", {
      method: "POST",
      body: formData,
    }).catch((error) => {
      console.error("Error submitting form:", error);
    });

    alert("Solicitud enviada");
    e.target.reset();
  };

  return (
    <Container>
      <InfoBlock>
        <Image src="/infinity.jpg" alt="Concepto Infinity" />
        <Text>
          El infinito es un concepto que desafía la comprensión humana y despierta la imaginación. Representa la posibilidad de lo ilimitado, un viaje sin fin a través de ideas, sueños y descubrimientos. En nuestro proyecto, Infinity simboliza la unión de creatividad, tecnología y pasión, invitando a explorar nuevos horizontes y a romper barreras en la búsqueda de un futuro lleno de oportunidades. Cada detalle está pensado para inspirar a quienes se atreven a soñar en grande.
        </Text>
      </InfoBlock>
      <FormBlock>
        <h3>Registro</h3>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            id="nombre"
            name="nombre"
            placeholder="Ingrese su nombre"
            required
          />
          <Input
            type="text"
            id="apellido"
            name="apellido"
            placeholder="Ingrese su apellido"
            required
          />
          <Input
            type="email"
            id="email"
            name="email"
            placeholder="ejemplo@correo.com"
            required
          />
          <Input
            type="tel"
            id="telefono"
            name="telefono"
            placeholder="+34 600 000 000"
            required
          />
          <AgreementContainer>
            <input type="checkbox" id="terms" name="terms" required />
            <label htmlFor="terms">
              Acepto los{" "}
              <a href="/terms"  rel="noopener noreferrer">
                Términos y Condiciones
              </a>{" "}
              y la{" "}
              <a href="/cookies"  rel="noopener noreferrer">
                Política de Cookies
              </a>.
            </label>
          </AgreementContainer>
          <Button type="submit">Enviar solicitud</Button>
        </Form>
      </FormBlock>
    </Container>
  );
};

export default InfinityComponent;
