import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #333;
  color: #fff;
  padding: 15px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
`;

const BannerText = styled.p`
  margin: 0;
  font-size: 14px;
`;

const BannerButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  background: #3b82f6;
  border: none;
  border-radius: 4px;
  color: #fff;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background: #2563eb;
  }
`;

const CookieBanner = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Проверяем, дали ли уже пользователи согласие на cookies
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <Banner>
      <BannerText>
        Utilizamos cookies para mejorar su experiencia en nuestro sitio web. Por favor, acepte su uso.
      </BannerText>
      <BannerButtons>
        <Button onClick={handleAccept}>Aceptar</Button>
        <Button onClick={handleDecline}>Rechazar</Button>
      </BannerButtons>
    </Banner>
  );
};

export default CookieBanner;
