import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  background-color: #000;
  color: #fff;
  padding: 40px 20px;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CompanyInfo = styled.div`
  flex: 1;
  min-width: 250px;
  margin-bottom: 20px;
`;

const CompanyName = styled.h3`
  margin-bottom: 10px;
`;

const ContactInfo = styled.p`
  margin: 5px 0;
`;

const FooterLinks = styled.div`
  flex: 1;
  min-width: 250px;
  margin-bottom: 20px;
`;

const SocialMedia = styled.div`
  flex: 1;
  min-width: 250px;
  margin-bottom: 20px;
`;

const SocialLink = styled.a`
  color: #fff;
  text-decoration: none;
  display: block;
  margin: 5px 0;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  border-top: 1px solid #444;
  padding-top: 20px;
  font-size: 14px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <CompanyInfo>
          <CompanyName>Infinity Tech España</CompanyName>
          <ContactInfo>
            Dirección: Calle Gran Vía, 28, 28013 Madrid, España
          </ContactInfo>
          <ContactInfo>Teléfono: +34 912 345 678</ContactInfo>
          <ContactInfo>Email: info@infinitytech.es</ContactInfo>
        </CompanyInfo>
        <FooterLinks>
          <CompanyName>Enlaces</CompanyName>
          <ContactInfo>
            <SocialLink href="/" target="_blank" rel="noopener noreferrer">
              Inicio
            </SocialLink>
          </ContactInfo>
          <ContactInfo>
            <SocialLink href="/about" target="_blank" rel="noopener noreferrer">
              Sobre nosotros
            </SocialLink>
          </ContactInfo>
          <ContactInfo>
            <SocialLink href="/contact" target="_blank" rel="noopener noreferrer">
              Contacto
            </SocialLink>
          </ContactInfo>
        </FooterLinks>
        <SocialMedia>
          <CompanyName>Síguenos</CompanyName>
          <SocialLink href="https://www.facebook.com/InfinityTechEspana" target="_blank" rel="noopener noreferrer">
            Facebook
          </SocialLink>
          <SocialLink href="https://twitter.com/InfinityTechEs" target="_blank" rel="noopener noreferrer">
            Twitter
          </SocialLink>
          <SocialLink href="https://www.linkedin.com/company/infinity-tech-espana" target="_blank" rel="noopener noreferrer">
            LinkedIn
          </SocialLink>
          <SocialLink href="https://www.instagram.com/infinitytechespana" target="_blank" rel="noopener noreferrer">
            Instagram
          </SocialLink>
        </SocialMedia>
      </FooterContent>
      <FooterBottom>
        &copy; {new Date().getFullYear()} Infinity Tech España. Todos los derechos reservados.
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
