import React, { useState } from "react";
import styled from "styled-components";

// Контейнер для FAQ
const FAQContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Отдельный элемент FAQ
const FAQItem = styled.div`
  border-bottom: 1px solid #ddd;
`;

// Заголовок вопроса
const Question = styled.h3`
  margin: 0;
  padding: 15px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
`;

// Текст ответа
const Answer = styled.div`
  padding: 15px;
  font-size: 16px;
  color: #666;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const BloqueFAQ = () => {
  const faqItems = [
    {
      question: "¿Qué es Infinity?",
      answer:
        "Infinity es un proyecto innovador que integra inteligencia artificial con soluciones empresariales, ofreciendo herramientas avanzadas para transformar el mercado."
    },
    {
      question: "¿Cómo puedo unirme a Infinity?",
      answer:
        "Puedes registrarte en nuestro sitio web y seguir los pasos indicados para formar parte de nuestra comunidad, obteniendo acceso a contenido exclusivo y beneficios especiales."
    },
    {
      question: "¿Cuáles son los beneficios de unirme?",
      answer:
        "Obtendrás acceso a tecnologías de punta, automatización de procesos, soporte continuo y oportunidades de networking con líderes del sector."
    },
    {
      question: "¿Qué tipo de proyectos se desarrollan?",
      answer:
        "Nos especializamos en proyectos de inteligencia artificial, automatización y desarrollo tecnológico, enfocados en revolucionar la forma en que las empresas operan."
    },
    {
      question: "¿Es necesario tener experiencia previa?",
      answer:
        "No es obligatorio, ya que Infinity está diseñado para ser accesible tanto para principiantes como para profesionales experimentados en tecnología."
    },
    {
      question: "¿Dónde puedo encontrar más información?",
      answer:
        "Visita nuestro sitio web o contacta a nuestro equipo para obtener más detalles sobre nuestros proyectos y oportunidades de colaboración."
    }
  ];

  const [openItems, setOpenItems] = useState(
    Array(faqItems.length).fill(false)
  );

  const toggleItem = (index) => {
    setOpenItems((prev) => {
      const newOpenItems = [...prev];
      newOpenItems[index] = !newOpenItems[index];
      return newOpenItems;
    });
  };

  return (
    <FAQContainer>
      {faqItems.map((item, index) => (
        <FAQItem key={index}>
          <Question onClick={() => toggleItem(index)}>
            {item.question} <span>{openItems[index] ? "-" : "+"}</span>
          </Question>
          <Answer isOpen={openItems[index]}>{item.answer}</Answer>
        </FAQItem>
      ))}
    </FAQContainer>
  );
};

export default BloqueFAQ;
