import React from "react";
import styled from "styled-components";

// Contenedor principal para el bloque del proyecto
const ContenedorProyecto = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 40px 20px;
  justify-content: center;
  background-color: #e8f0fe;
`;

// Contenedor para el video con relación de aspecto 16:9
const ContenedorVideo = styled.div`
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Iframe responsivo para el video
const IframeVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

// Contenedor para el contenido del proyecto
const ContenedorContenido = styled.div`
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// Título del proyecto
const TituloProyecto = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
`;

// Descripción del proyecto
const DescripcionProyecto = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.5;
`;

const BloqueProyectoStartup = () => {
  return (
    <ContenedorProyecto>
      <ContenedorVideo>
        <IframeVideo
          src="https://www.youtube.com/embed/Go4nCVnE4aY?si=TnjmIm16JPXEWvrH"
          title="Video del Proyecto Startup"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </ContenedorVideo>
      <ContenedorContenido>
        <TituloProyecto>Nuevo Proyecto Startup: Recaudación de Fondos</TituloProyecto>
        <DescripcionProyecto>
          Nuestro proyecto tiene como objetivo recaudar fondos para lanzar un startup innovador en el campo de la inteligencia artificial. Al invertir en este proyecto, tendrás la oportunidad de formar parte de una revolución tecnológica que automatiza procesos empresariales, optimiza operaciones y abre nuevas oportunidades en el mundo de la tecnología. ¡Únete a nosotros para transformar ideas en realidades y construir el futuro!
        </DescripcionProyecto>
      </ContenedorContenido>
    </ContenedorProyecto>
  );
};

export default BloqueProyectoStartup;
