import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  max-width: 800px;
  margin: 120px auto 40px; /* враховується висота Header */
  padding: 40px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  font-size: 32px;
  margin-bottom: 20px;
  color: #333;
`;

const Content = styled.div`
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  p {
    margin-bottom: 16px;
  }
`;

const TermsAndConditions = () => {
  return (
    <>

      <Container>
        <Title>Términos y Condiciones</Title>
        <Content>
          <p>
            Bienvenido a nuestro sitio web. Estos Términos y Condiciones regulan el uso de nuestros servicios y contenidos. Al acceder y utilizar este sitio, usted acepta cumplir con estos términos. Si no está de acuerdo, por favor, no utilice el sitio.
          </p>
          <p>
            <strong>1. Uso del Sitio Web:</strong> Usted se compromete a utilizar el sitio de manera responsable y de acuerdo con la ley. No se permite el uso del sitio para actividades ilegales.
          </p>
          <p>
            <strong>2. Propiedad Intelectual:</strong> Todo el contenido, incluyendo textos, imágenes, logotipos y gráficos, es propiedad de la empresa y está protegido por las leyes de propiedad intelectual.
          </p>
          <p>
            <strong>3. Limitación de Responsabilidad:</strong> No seremos responsables de daños o pérdidas derivadas del uso del sitio web.
          </p>
          <p>
            <strong>4. Cambios en los Términos:</strong> Nos reservamos el derecho de modificar estos términos en cualquier momento. Es responsabilidad del usuario revisar estos términos periódicamente.
          </p>
          <p>
            <strong>5. Legislación Aplicable:</strong> Estos términos se regirán e interpretarán de acuerdo con las leyes vigentes en el país en el que opera nuestra empresa.
          </p>
          <p>
            Para cualquier consulta sobre estos Términos y Condiciones, por favor contáctenos.
          </p>
        </Content>
      </Container>
    </>
  );
};

export default TermsAndConditions;
