import React from "react";
import styled from "styled-components";

// Контейнер для блока местоположения
const UbicacionContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// Заголовок блока
const Titulo = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

// Контейнер для карты
const MapaContainer = styled.div`
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const BloqueUbicacion = () => {
  return (
    <UbicacionContainer>
      <Titulo>Estamos ubicados en Barcelona, España</Titulo>
      <MapaContainer>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.531940544792!2d2.154007315887753!3d41.39020547926451!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4986ddf80f8b1%3A0x9872e0123a8a36de!2sBarcelona%2C%20Spain!5e0!3m2!1sen!2s!4v1615588055604!5m2!1sen!2s"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Mapa de Barcelona"
        ></iframe>
      </MapaContainer>
    </UbicacionContainer>
  );
};

export default BloqueUbicacion;
