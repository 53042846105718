import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const HeaderWrapper = styled.header`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    transition: all 0.3s ease;
    background-color: ${({ isScrolled }) => (isScrolled ? "#ffffff" : "transparent")};
    padding: ${({ isScrolled }) => (isScrolled ? "10px 0" : "20px 0")};
    box-shadow: ${({ isScrolled }) => (isScrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none")};
`;

const HeaderContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${({ isScrolled }) => (isScrolled ? "#ffffff" : "gray")};
    position: relative;
`;

const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
`;

const LogoIcon = styled.div`
    width: 50px;
    height: 50px;
    background: linear-gradient(90deg, #3b82f6, #8b5cf6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    & span {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
    }
`;

const LogoText = styled.span`
    font-size: 28px;
    font-weight: bold;
    color: #333;
`;

const NavList = styled.ul`
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
    @media (max-width: 768px) {
        display: none;
    }
`;

const NavItem = styled.li``;

const NavLink = styled(Link)`
    text-decoration: none;
    color: ${({ $active }) => ($active ? "#3b82f6" : "#666")};
    font-size: 18px;
    transition: color 0.3s;
    font-weight: ${({ $active }) => ($active ? "bold" : "normal")};

    &:hover {
        color: #3b82f6;
        font-weight: bold;
    }
`;

const Hamburger = styled.div`
    display: none;
    flex-direction: column;
    cursor: pointer;
    @media (max-width: 768px) {
        display: flex;
    }
`;

const Bar = styled.span`
    height: 3px;
    width: 25px;
    background-color: #333;
    margin: 3px 0;
    transition: 0.4s;
`;

const MobileMenu = styled.ul`
    list-style: none;
    flex-direction: column;
    gap: 10px;
    padding: 10px 20px;
    background-color: #fff;
    position: absolute;
    top: 100%;
    right: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: ${({ open }) => (open ? "flex" : "none")};
    @media (min-width: 769px) {
        display: none;
    }
`;

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <HeaderWrapper isScrolled={isScrolled}>
      <HeaderContainer isScrolled={isScrolled}>
        <LogoLink to="/">
          <LogoIcon>
            <span>I</span>
          </LogoIcon>
          <LogoText>InfinityQ</LogoText>
        </LogoLink>

        <NavList>
          <NavItem>
            <NavLink to="/" $active={location.pathname === "/"}>
              Inicio
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/about" $active={location.pathname === "/about"}>
              Sobre nosotros
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/contact" $active={location.pathname === "/contact"}>
              Contacto
            </NavLink>
          </NavItem>
        </NavList>

        <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <Bar />
          <Bar />
          <Bar />
        </Hamburger>

        <MobileMenu open={isMenuOpen}>
          <li>
            <NavLink to="/" $active={location.pathname === "/"} onClick={handleLinkClick}>
              Inicio
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" $active={location.pathname === "/about"} onClick={handleLinkClick}>
              Sobre nosotros
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" $active={location.pathname === "/contact"} onClick={handleLinkClick}>
              Contacto
            </NavLink>
          </li>
        </MobileMenu>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
