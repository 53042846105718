import React from "react";
import styled from "styled-components";

// Contenedor para todas las tarjetas
const ContenedorPrivilegios = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 40px 20px;
  background-color: #f0f4f8;
`;

// Tarjeta individual
const Tarjeta = styled.div`
  flex: 1;
  min-width: 280px;
  max-width: 320px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

// Imagen de la tarjeta
const ImagenTarjeta = styled.img`
  width: 100%;
  height: 180px;
  object-fit: cover;
`;

// Contenedor para el contenido textual de la tarjeta
const ContenidoTarjeta = styled.div`
  padding: 20px;
`;

// Título de la tarjeta
const TituloTarjeta = styled.h3`
  font-size: 20px;
  color: #333;
  margin: 0 0 10px;
`;

// Descripción de la tarjeta
const DescripcionTarjeta = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.4;
`;

const PrivilegiosInfinity = () => {
  return (
    <ContenedorPrivilegios>
      <Tarjeta>
        <ImagenTarjeta
          src="/reacti3.jpg"
          alt="Acceso Rápido"
        />
        <ContenidoTarjeta>
          <TituloTarjeta>Acceso Rápido</TituloTarjeta>
          <DescripcionTarjeta>
            Al unirte a Infinity, obtendrás acceso inmediato a las tecnologías de punta, permitiéndote estar siempre un paso adelante de la competencia.
          </DescripcionTarjeta>
        </ContenidoTarjeta>
      </Tarjeta>
      <Tarjeta>
        <ImagenTarjeta
          src="/reacti2.jpg"
          alt="Automatización Empresarial"
        />
        <ContenidoTarjeta>
          <TituloTarjeta>Automatización Empresarial</TituloTarjeta>
          <DescripcionTarjeta>
            Infinity automatiza procesos clave de negocio, optimizando operaciones y facilitando la gestión eficiente de tus recursos.
          </DescripcionTarjeta>
        </ContenidoTarjeta>
      </Tarjeta>
      <Tarjeta>
        <ImagenTarjeta
          src="/reacti1.jpg"
          alt="Desarrollo de IA"
        />
        <ContenidoTarjeta>
          <TituloTarjeta>Desarrollo de IA</TituloTarjeta>
          <DescripcionTarjeta>
            Nuestro programa se especializa en desarrollar algoritmos de inteligencia artificial que abren nuevas oportunidades para el crecimiento y la innovación de tu empresa.
          </DescripcionTarjeta>
        </ContenidoTarjeta>
      </Tarjeta>
    </ContenedorPrivilegios>
  );
};

export default PrivilegiosInfinity;
